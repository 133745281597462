import type { FC } from 'react';
import React, { memo, useEffect } from 'react';
import { defineMessages } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	PoweredByTemplateCard,
	PoweredByTemplateErrorCard,
	PoweredByTemplateLoadingCard,
} from '@confluence/template-card';

import type {
	TemplateInfoQuery as TemplateInfoQueryType,
	TemplateInfoQueryVariables,
} from './__types__/TemplateInfoQuery';
import { TemplateInfoQuery } from './TemplateInfoQuery.graphql';

export const i18n = defineMessages({
	viewButton: {
		id: 'powered-by-templates.popup.view-template-button',
		defaultMessage: 'View',
		description: 'Button text in the template card to view the template',
	},
});

type PopupComponentProps = {
	templateId: string;
};

/**
 * The **popup** component for Powered By.
 * Returns a {@linkcode PoweredByTemplateCard} filled with information about the template used on the page.
 * @param templateId The page's source template key.
 * @constructor
 */
const Component: FC<PopupComponentProps> = ({ templateId }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, loading, error } = useQuery<TemplateInfoQueryType, TemplateInfoQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		TemplateInfoQuery,
		{ variables: { templateId } },
	);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'poweredByTemplatePopup',
				source: 'viewPageScreen',
				attributes: { templateId },
			},
		}).fire();
	}, [createAnalyticsEvent, templateId]);

	if (loading) {
		return <PoweredByTemplateLoadingCard data-testid="powered-by-template-loading-test-id" />;
	}

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
			return <PoweredByTemplateErrorCard errorState="permission" />;
		}
		return (
			<ErrorDisplay error={error}>
				<PoweredByTemplateErrorCard errorState="unknown" />
			</ErrorDisplay>
		);
	}

	const templateObject = data?.templateInfo;
	if (!templateObject) {
		return <PoweredByTemplateErrorCard errorState="deleted" />;
	}

	return <PoweredByTemplateCard template={templateObject} />;
};

export const PoweredByTemplatesPopup: FC<PopupComponentProps> = memo(Component);
